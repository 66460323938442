<template>
  <div>
    <ProductHeader />
    <v-container grid-list-xs xs="12" lg="6" sm="5" md="4">
      <div v-if="product.length >= 1">
        <v-col class="w-full mx-0 px-0 my-2 bg-red mb-4" v-for="(v, i) in Object.keys(prod_for())" :key="i">
          <h3 class="nunito mb-6 font-weight-bold leaf--text red--text">
          {{ v }}
        </h3>
          <v-lazy :value="product ? true : false" :options="{
            threshold: 0.5,
          }" transition="fade-transition" v-for="(value, index) in prod_for()[v]" :key="index">
            <v-card class="mt-2 mb-5" style="border-radius: 20px;" @click="selectProduct(value)" :disabled="value.total_left == 0 || value.out_of_stock ? true : false
              ">
              <v-row class="card-product">
                <v-col lg="2" class="card-img">
                  <div class="products_list_content_selected_cart" v-if="renderQuantityFromCart(value) > 0">
                      <div class="products_list_content_selected_cart_text">{{ renderQuantityFromCart(value) }}</div>
                    </div>
                  <v-img class="img-product" v-if="value.media_urls.length > 0" max-width="120" max-height="140"
                    :src="image(value.media_urls[0])"></v-img>
                  <v-img class="img-product" max-width="120" max-height="140" v-else :src="emptyImage()"></v-img>
                </v-col>
                <v-col lg="6" :class="`card-text flex flex-column justify-space-between ${value.media_urls.length > 0 ? 'pl-2' : 'pl-5'
                  }`">
                  <div class="mt-3 pt-3">
                    <h4 class="nunito font-weight-bold">{{ value.name }}</h4>
                    <!-- <h4 class="nunito font-weight-bold blue-grey--text elipsis">
                      {{ value.description }}
                    </h4> -->
                    
                    <!-- <h5 class="lato red--text">
                      {{ value.tags.length > 0 ? v.name : "" }}
                    </h5> -->
                  </div>
                  
                  <div class="flex flex-column">
                    <h5 class="lato font-weight-bold font-italic red--text"
                      v-if="value.total_left == 0 || value.out_of_stock">
                      Sold Out
                    </h5>
                    <div v-else class="flex">
                      <h5 class="lato font-weight-bold" v-if="value.price_type == 0">
                        <div v-if="value.variants.length > 1">
                          RM {{ currency(getMinVariantPrice(value.variants)) }} - RM {{
                            currency(getMaxVariantPrice(value.variants)) }}
                        </div>
                        <div v-else>RM {{ currency(value.unit_price) }}

                        </div>
                      </h5>
                      <h5 class="lato font-weight-bold" v-else>
                        RM {{ currency(value.unit_price) }}
                      </h5>
                      <h5 class="
                          lato
                          font-weight-bold
                          grey--text
                          line-through
                        " v-if="value.before_discount">
                        RM {{ currency(value.before_discount) }}
                      </h5>
                    </div>
                  </div>
                  
                  
                </v-col>
                <v-col lg="1" class="card-btn pt-12 pb-12 pr-5 ml-5" style="background-color: #F85757;">
                  <div class="btn-add">
                    <h5 text style="text-transform: none; font-weight: 600; color: white;">Add</h5>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-lazy>
        </v-col>
      </div>

      <div v-else>
        <h3 class="blue-grey--text text-center my-16">No items available</h3>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductHeader from "../../../common/ProductHeader.vue";

export default {
  name: "list",
  components: {
    ProductHeader,
  },

  data() {
    return {
      first: null,
    };
  },

  computed: {
    ...mapGetters({
      franchise: "getFranchise",
      product: "getFilteredProducts",
      cart: "getCart",
    }),

    currency() {
      return require("currency.js");
    },

    // tag_list() {
    //   var tags = {};
    //   this.product.forEach((prod) => {
    //     prod.tags.forEach(function (tag) {
    //       if (!tag.hidden_tag) {
    //         return (tags[tag.id] = tag);
    //       }
    //     });
    //   });
    //   return Object.values(tags).sort(function (a, b) {
    //     if (a.name < b.name) {
    //       return -1;
    //     }
    //     if (a.name > b.name) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    // },
  },

  methods: {
    renderQuantityFromCart(product) {
      const cartItem = this.cart.find((item) => item.id === product.id);
      if (cartItem) {
        console.log("cartItem", this.cart);
        console.log(cartItem);
        return cartItem ? cartItem.quantity : 0;
      }
      return cartItem ? cartItem.quantity : 0;
    },
    goToUserDetail() {
      this.$router.push({
        name: "UserDetail",
        params: { child: this.$route.params.child, outlet_id: 12, table_id: 12, page: "user-detail" },
      });
    },
    getMinVariantPrice(variants) {
      return Math.min(...variants.map((variant) => variant.price));
    },
    getMaxVariantPrice(variants) {
      return Math.max(...variants.map((variant) => variant.price));
    },
    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },

    selectProduct(i) {
      this.$store.dispatch("updateSP", i);
      this.track(i.name);
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if (table_id && outlet_id) { // static link
        this.$router.push({
          name: "ProductsDetail",
          params: { child: this.$route.params.child, outlet_id, table_id, page: "product", }
        });
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionProductsDetail",
          params: { session }
        });
      } else {
        // shop link
        this.$router.push({
          name: "ShoplinkProductsDetail",
          params: { child: this.$route.params.child, page: "product", }
        });
      }
    },

    track(val) {
      this.$gtag.event("view_product", {
        event_category: "view_product",
        event_label: "view_product",
        value: val,
      });
    },

    

    prod_for() {
      var list = this.product
      // console.log("list", JSON.parse(JSON.stringify(list)));
      var priority_list = [
        ...list.filter((p) => p.media_urls.length > 0),
        ...list.filter((p) => p.media_urls.length == 0),
      ];
      priority_list = priority_list.sort(function (a, b) {
        var names = { a: a.code || a.name, b: b.code || b.name };
        if (names.a < names.b) {
          return -1;
        }
        if (names.a > names.b) {
          return 1;
        }
        return 0;
      });
      const groupedByTag = {};

      // Iterate through the array and group by the first element of the 'tag' array
      for (const obj of priority_list) {
        if (obj.tags.length > 0) {

          // for(const tag of obj.tags){
          if (groupedByTag[obj.tags[0].name]) {
            groupedByTag[obj.tags[0].name].push(obj);
          } else {
            groupedByTag[obj.tags[0].name] = [obj];
          }
          // }

        }
      }
      return groupedByTag;
    },

    // prod_for(tag) {
    //   var list = this.product.filter((prod) =>
    //     prod.tags.some((t) => t.id == tag.id && !tag.hidden_product)
    //   );
    //   var priority_list = [
    //     ...list.filter((p) => p.media_urls.length > 0),
    //     ...list.filter((p) => p.media_urls.length == 0),
    //   ];
    //   priority_list = priority_list.sort(function (a, b) {
    //     var names = { a: a.code || a.name, b: b.code || b.name };
    //     if (names.a < names.b) {
    //       return -1;
    //     }
    //     if (names.a > names.b) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    //   return priority_list;
    // },
  },

  mounted() {
    //  console.log("list");
    // this.scroll(this.tag_list[0].name);
    // if (this.tag_list.length > 0) {
    //   this.first = this.tag_list[0].name;
    // }
  },

  created() { },
};
</script>
<style lang="scss">

.products_list_content_selected_cart{
  position: absolute;
  left: 5px;
  z-index: 100;
  top: 5px;

  width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #F85757;
        color: white;
        justify-content: center;
        font-weight: 600;
        align-items: center;
        display: flex;
}
.elipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line-through {
  text-decoration: line-through red;
}

.v-slide-group__prev {
  display: none !important;
  visibility: hidden;
}

.card-product {
  display: flex;
}

.card-img {
  flex: 1 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.img-product {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.card-text {
  flex: 1 !important;
  margin-left: -10px !important;
}

.card-btn {
  flex: 0.5 !important;
  margin-right: 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.btn-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 300px) {
  .card-product {
    display: flex;

  }

  .card-img {
    flex: 1 !important;
  }

  .img-product {
    width: 80px;
    height: 125px;
  }

  .card-text {
    flex: 1 !important;
    margin-left: -8px !important;
    font-size: 11px;
  }

  .card-btn {
    flex: 0.3 !important;
  }

}

@media screen and (min-width: 301px) and (max-width:390px) {
  .card-product {
    display: flex;

  }

  .card-img {
    flex: 1 !important;
  }

  .img-product {
    width: 100px;
    height: 140px;
  }

  .card-text {
    flex: 1 !important;
    margin-left: -15px !important;
    font-size: 13px;
  }

  .card-btn {
    flex: 0.5 !important;
  }

}

@media screen and (min-width: 500px) and (max-width:699px) {
  .card-product {
    display: flex;

  }

  .card-img {
    flex: 1 !important;
  }

  .img-product {
    width: 230px;
    height: 140px;
  }

  .card-text {
    flex: 2 !important;
  }

  .card-btn {
    flex: 0.5 !important;
  }

}

@media screen and (min-width: 700px) and (max-width:1263px) {
  .card-product {
    display: flex;

  }

  .card-img {
    flex: 1 !important;
  }

  .img-product {
    width: 230px;
    height: 140px;
  }

  .card-text {
    flex: 2 !important;
  }

  .card-btn {
    flex: 0.5 !important;
  }

}

@media screen and (min-width: 1264px) and (max-width: 3000px) {
  .card-product {
    display: flex;

  }

  .card-img {
    flex: 1 !important;
  }

  .img-product {
    width: 200px !important;
    height: 200px !important;
    margin-bottom: 0px !important;
  }

  .card-text {
    flex: 4 !important;
    margin-left: 60px !important;
    margin-right: 85px !important;

  }

  .card-btn {
    flex: 1 !important;
    margin-left: 50px !important;
  }

  .btn-add {
    padding-top: 10px;
  }
}
</style>
